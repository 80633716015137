<template>
  <v-dialog
    v-model="isOpen"
    max-width="500px"
    @click:outside="closeModal()">
    <v-card>
      <v-card-title
        v-if="productData"
        class="text-h5">
        {{ title }}
      </v-card-title>
      <v-col
        col="12"
      >
        <v-row>
          <v-col cols="3">
            <v-img
              :src="product.photoUrl ? `${product.photoUrl}?fm=webp&w=100&h=150` : imageDefault">
            </v-img>
          </v-col>
          <v-col
            cols="9">
            <v-card-text>
              <p>{{ product.name }}</p>
              <p>{{ product.model }}</p>
              <p>{{ product.price | showNumberFormat() }} THB</p>
              <v-checkbox
                v-if="type != 'delete'"
                v-model="productData.dailyNoti"
                label="ต้องการให้แจ้งเตือนทุกวัน"
              />
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="d-flex justify-center">
        <v-btn
          color="error"
          outlined
          @click="closeProductModal">
          ยกเลิก
        </v-btn>

        <v-btn
          class="btn-confirm"
          color="black"
          @click="confirmAddProduct"
        >
          ยืนยัน
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import defaultImg from '@/assets/image/no_picture_available.png'

export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: true,
      productData: { ...this.product },
      imageDefault: defaultImg
    }
  },
  methods: {
    closeProductModal () {
      this.$emit('close')
      this.isOpen = false
    },
    confirmAddProduct () {
      this.productData.dailyNoti = this.productData.dailyNoti || false
      if (this.type === 'create') {
        this.$emit('confirm', this.productData)
      } else if (this.type === 'edit') {
        this.$emit('confirm', this.productData)
      } else if (this.type === 'delete') {
        this.$emit('delete', this.productData)
      }
    },
    closeModal () {
      this.$emit('close')
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

.btn-confirm {
  margin-left: 1em;
  color: #ffffff;
}
</style>
