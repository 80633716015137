<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row>
      <v-col cols="4">
        <v-row
          class="products-row"
          style="height: calc(100vh - 100px)">
          <v-col
            cols="12">
            <side-bar
              :products="products"
              :fetching-product="gettingProducts"
              @add-product="createNotify"
              @search="getProducts($event)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-row class="products-row">
          <v-col cols="12">
            <product-notified-table
              :call-fetch-notify-in-child="callFetchNotifyInChild" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SideBar from '../components/sideBar.vue'
import ProductNotifiedTable from '../components/ProductNotifiedTable.vue'
import ProductProvider from '@/resources/ProductProvider'
import StockNotifyProvider from '@/resources/StockNotifyProvider'
import { mapActions } from 'vuex'

const ProductService = new ProductProvider()
const StockNotifyService = new StockNotifyProvider()
export default {
  components: {
    SideBar,
    ProductNotifiedTable
  },
  data () {
    return {
      gettingProducts: false,
      selectedProduct: [],
      products: [],
      callFetchNotifyInChild: false
    }
  },
  async mounted () {
    await this.getProducts()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getProducts (search = '') {
      try {
        this.gettingProducts = true
        const { data } = await ProductService.getProductsList({ search })
        this.products = this.mappingProducts(data.results)
      } catch (error) {
        console.error('getting product', error)
        this.setErrorPage(error.code)
      } finally {
        this.gettingProducts = false
      }
    },
    mappingProducts (products) {
      const allProducts = []
      products.forEach((product) => {
        allProducts.push({
          productId: product.id,
          name: product.name,
          model: product.model,
          price: product.price,
          photoUrl: product.photoUrls[0]
        })
      })
      return allProducts
    },
    async createNotify (product) {
      try {
        await StockNotifyService.createStockNotify(product)
        this.callFetchNotifyInChild = !this.callFetchNotifyInChild
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: 'สินค้านี้อยู่ในรายการแจ้งเตือนแล้ว',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
