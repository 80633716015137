<template>
  <v-row style="">
    <v-col
      cols="12"
      class="text-center">
      <h3>เลือกสินค้าเพื่อแจ้งเตือน</h3>
    </v-col>
    <v-col cols="12">
      <search-box
        v-model="searchText"
        placeholder="ชื่อสินค้า หรือ รหัสสินค้า"
        @on-search="search" />
    </v-col>
    <v-col
      v-if="!fetchingProduct"
      cols="12">
      <v-row class="product-search-list">
        <v-col
          v-for="(product, index) in products"
          :key="`product-list-${index}`"
          cols="12">
          <v-row>
            <v-col cols="3">
              <img
                :src="product.photoUrl ? `${product.photoUrl}?fm=webp&w=100&h=150` : imageDefault"
                alt="">
            </v-col>
            <v-col cols="7">
              <p class="mb-0">
                {{ avoidNullValue(product, 'name') }}
              </p>
              <p class="mb-0">
                {{ avoidNullValue(product, 'model') }}
              </p>
              <p class="mb-0">
                {{ product.price | showNumberFormat() }} THB
              </p>
            </v-col>
            <v-col cols="2">
              <div>
                <v-btn
                  small
                  color="primary"
                  @click="openProductDialog(product)">
                  เพิ่ม
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0">
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-else
      cols="12">
      <v-row
        class="product-search-list">
        <v-col
          cols="12"
          class="d-flex justify-center align-center">
          <div class="loading">
            <span class="loading-dots">•</span>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <ProductNotifiedModal
      v-if="isModalOpen"
      :product="selectedProduct"
      :title="title"
      :type="type"
      @close="closeProductModal"
      @confirm="confirmAddProduct($event)"
    />
  </v-row>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import ProductNotifiedModal from './ProductNotifiedModal.vue'
import defaultImg from '@/assets/image/no_picture_available.png'

export default {
  components: { SearchBox, ProductNotifiedModal },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    fetchingProduct: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      blank: 0,
      searchText: '',
      isDailyNoti: false,
      isModalOpen: false,
      imageDefault: defaultImg,
      selectedProduct: {},
      title: '',
      type: ''
    }
  },
  methods: {
    search () {
      this.$emit('search', this.searchText)
    },
    openProductDialog (product) {
      this.selectedProduct = product
      this.title = 'เพิ่มรายการนี้ไปยังการแจ้งเตือนหรือไม่'
      this.type = 'create'
      this.isModalOpen = true
    },
    closeProductModal () {
      this.isModalOpen = false
    },
    confirmAddProduct (product) {
      this.$emit('add-product', product)
      this.isModalOpen = false
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    }
  }
}
</script>

<style>
.number-field.v-input input {
  text-align: center;
}

</style>

<style scoped>
.product-search-list {
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
}

.loading {
  margin: 3em 0;
}

.loading-dots {
  text-align: center;
  font-size: 2em;
  color: rgba(0,0,0,0);
  animation-name: loading-dots-animation;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loading-dots-animation {
  0% {
    text-shadow: -1em 0 0 rgba(0,0,0,.6), 0em 0 0 rgba(0,0,0,.2), 1em 0 0 rgba(0,0,0,.2);
  }
  20% {
    text-shadow: -1em 0 0 rgba(0,0,0,1), 0em 0 0 rgba(0,0,0,.6), 1em 0 0 rgba(0,0,0,.2);
  }
  40% {
    text-shadow: -1em 0 0 rgba(0,0,0,.6), 0em 0 0 rgba(0,0,0,1), 1em 0 0 rgba(0,0,0,0.6);
  }
  60% {
    text-shadow: -1em 0 0 rgba(0,0,0,.2), 0em 0 0 rgba(0,0,0,.6), 1em 0 0 rgba(0,0,0,1);
  }
  80% {
    text-shadow: -1em 0 0 rgba(0,0,0,.2), 0em 0 0 rgba(0,0,0,.2), 1em 0 0 rgba(0,0,0,.6);
  }
  100% {
    text-shadow: -1em 0 0 rgba(0,0,0,.6), 0em 0 0 rgba(0,0,0,.2), 1em 0 0 rgba(0,0,0,.2);
  }
}

.loading-text {
  font-weight: bold;
  color: #000;
  margin: 0.3em 0;
  font-size: 1.5em;
}
</style>
