<template>
  <div>
    <v-data-table
      class="table"
      :headers="headers"
      :items="products"
      :loading="loading"
      height="calc(100vh - 240px)"
      hide-default-footer
      @update:options="fetchData"
    >
      <template v-slot:top>
        <div>
          <h2>รายการสินค้าที่ต้องการแจ้งเตือนเมื่อมีสินค้าในสต็อก</h2>
        </div>
      </template>
      <template v-slot:body>
        <tr
          v-for="product in products"
          :key="product.id"
          height="50px"
          class="text-body-2"
        >
          <td class="text-left">
            {{ product.name }}
          </td>
          <td class="text-left">
            {{ product.model }}
          </td>
          <td class="text-center">
            {{ $dayjs(product.createdAt).format('DD/MM/YYYY') }}
          </td>
          <td>
            <v-icon
              v-if="product.dailyNoti"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </td>

          <td>
            <v-btn
              icon
              @click="openProductModal(product)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="removeProduct(product)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>

        <ProductNotifiedModal
          v-if="isModalOpen"
          :product="selectedProduct"
          :title="title"
          :type="type"
          @close="closeProductModal"
          @confirm="isEditProduct"
          @delete="isDeleteProduct"
        />
      </template>
    </v-data-table>
    <div class="text-center mt-4">
      <v-pagination
        v-model="paginate.page"
        color="secondary"
        :length="paginate.totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import ProductNotifiedModal from './ProductNotifiedModal.vue'
import StockNotifyProvider from '@/resources/StockNotifyProvider'

const StockNotifyService = new StockNotifyProvider()
export default {
  components: { ProductNotifiedModal },
  props: {
    callFetchNotifyInChild: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paginate: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Model',
          value: 'model',
          sortable: false
        },
        {
          text: 'วันที่สนใจ',
          value: 'interestDate',
          align: 'center',
          sortable: false
        },
        {
          text: 'Daily',
          value: 'daily',
          align: 'center',
          sortable: false
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ],
      isModalOpen: false,
      loading: false,
      data: {},
      products: [],
      selectedProduct: {},
      title: '',
      type: ''
    }
  },
  watch: {
    callFetchNotifyInChild: {
      immediate: true,
      handler () {
        this.fetchData()
      }
    },
    paginate: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        const params = {
          page: this.paginate.page,
          limit: this.paginate.itemsPerPage === -1 ? 9999 : this.paginate.itemsPerPage
        }
        const response = await StockNotifyService.getStockNotifyList(params)
        this.products = response.data.results
        this.paginate.totalPages = response.data.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    removeProduct (product) {
      this.selectedProduct = product
      this.title = 'ต้องการลบรายการนี้หรือไม่'
      this.type = 'delete'
      this.isModalOpen = true
    },
    openProductModal (product) {
      this.selectedProduct = product
      this.title = 'ต้องการแก้ไขการแจ้งเตือนหรือไม่'
      this.type = 'edit'
      this.isModalOpen = true
    },
    closeProductModal () {
      this.isModalOpen = false
    },

    async isEditProduct (product) {
      try {
        this.loading = true
        const body = {
          dailyNoti: product.dailyNoti
        }
        const res = await StockNotifyService.updateStockNotify(product.id, body)
        if (res) {
          await this.fetchData()
        }
        this.closeProductModal()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },

    async isDeleteProduct (product) {
      try {
        this.loading = true
        await StockNotifyService.deleteStockNotify(product.id)
        await this.fetchData()
        this.closeProductModal()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.table td {
  text-align: center;
  margin: 12px;
}
</style>
