import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StockNotifyProvider extends HttpRequest {
  getStockNotifyList (payload) {
    this.setHeader(getAuthToken())
    return this.get('/stock-notify', payload)
  }

  createStockNotify (payload) {
    this.setHeader(getAuthToken())
    return this.post('/stock-notify', payload)
  }

  updateStockNotify (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/stock-notify/daily-noti/${id}`, payload)
  }

  deleteStockNotify (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/stock-notify/${id}`)
  }
}

export default StockNotifyProvider
